import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueGtag from 'vue-gtag';
import VueRouterUserRoles from 'vue-router-user-roles';
import vuelidate from 'vuelidate';
import * as svgicon from 'vue-svgicon';
import VTooltip from 'v-tooltip';
import vueDebounce from 'vue-debounce';
import VCalendar from 'v-calendar';
import moment from 'moment';
import Paginate from 'vuejs-paginate';
import VueToast from 'vue-toast-notification';
import i18n from '@/plugins/i18n';
import 'vue-toast-notification/dist/theme-sugar.css';

import App from './App.vue';
import router from './router';
import store from './store';

import checkToken from '@/helpers/checkToken';

Vue.config.productionTip = false;

Vue.component('paginate', Paginate);

Vue.use(VueRouterUserRoles, { router });
Vue.use(VueAxios, axios);
Vue.use(vueDebounce, {
  listenTo: ['input', 'keyup'],
  defaultTime: '700ms',
});
Vue.use(vuelidate);
Vue.use(svgicon, {
  classPrefix: 'svg-',
  isOriginalDefault: true,
});
Vue.use(VTooltip);
Vue.use(VCalendar, { componentPrefix: 'vc' });

Vue.use(VueToast, {
  position: 'top',
  duration: 3000,
  dismissible: true,
  pauseOnHover: true,
  queue: true,
});

const url = window.location.host;
if (url.includes('edu')) {
  Vue.use(VueGtag, {
    config: { id: 'G-BREYXBR0CL' },
  });
}

moment.locale(localStorage.getItem('locale') || 'lt');
Vue.prototype.moment = moment;

axios.defaults.baseURL = 'https://edu.reflectus.lt/api';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common.Language = localStorage.getItem('locale') || 'lt';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { data, message } = error.response.data;
    if (error.response.status !== 403) {
      if (data && data.email) {
        store.dispatch(
          'meta/setApiError',
          data.email || i18n.t('warnings.unexpected_error_occurred_message')
        );
      } else {
        store.dispatch(
          'meta/setApiError',
          message || i18n.t('warnings.unexpected_error_occurred_message')
        );
      }
    } else {
      if (message.error === 'license_expired') {
        store.dispatch('user/setError', { error: message.error });
      }
      store.dispatch('user/toggleModal', { type: 'login', status: true });
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token && !checkToken(token)) {
      store.dispatch('user/toggleModal', { type: 'login', status: true });
    }
    return config;
  },
  (err) => {
    store.commit('user/SET_ERROR', err);
    return Promise.reject(err);
  }
);

const authenticate = store.dispatch('user/silentLogin');
authenticate.then((user) => {
  Vue.prototype.$user.set(user);
  if (user.role === 'student' || user.role === 'teacher') {
    const { token } = store.state.user.user;
    if (checkToken(token)) {
      axios.defaults.headers.common['Digi-Token'] = token;
      store.dispatch('user/getCurrentUser');
    }
  }

  new Vue({
    router,
    store,
    i18n,
    vuelidate,
    render: (h) => h(App),
  }).$mount('#app');
});
